import React, { Component } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { StyleSheet, css } from 'aphrodite'
import Scroll from 'react-scroll'
import GastbyImg from 'gatsby-image'
import Img from 'react-image'
import Helmet from 'react-helmet'

import PrimaryLayout from './layouts/PrimaryLayout'
import Contact from './layouts/Contact'
import Button from '@material-ui/core/Button'
import HomeNews from './HomeNews'
import HomeReferences from './HomeReferences'

import Classes from '../styles/classes'
import i18next from '../config/i18n/context'
import pagesInfos from '../config/i18n/pagesInfos'

import Link from './Link'
import TennisIcon from './icons/Tennis'

const styles = StyleSheet.create({
  footer: {
    backgroundColor: '#002337'
  },
  button: {
    display: 'block',
    margin: '1em 0'
  },
  image: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0
  }
})

const IndexPage = () => {
  const lang =
    i18next.language ||
    (typeof window !== 'undefined' && window.localStorage.i18nextLng)
  console.log('language home', lang)
  return (
    <StaticQuery
      query={graphql`
        query AccueilImageQuery {
          header: file(relativePath: { eq: "header.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 6000, maxHeight: 4000) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      `}
      render={data => {
        return (
          <PrimaryLayout>
            <Helmet
              title="Tennis Club Uccle Churchill - Club, école et cours de tennis - Bruxelles"
              meta={[
                {
                  name: 'description',
                  content:
                    'Venez jouer au tennis à Uccle dans un cadre sympathique et verdoyant !'
                },
                { name: 'charset', content: 'UTF-8' }
              ]}
            />
            <Scroll.Element name="accueil" id="accueil">
              <section className="box-intro" style={{ position: 'relative' }}>
                <GastbyImg
                  fluid={data.header.childImageSharp.fluid}
                  className={css(styles.image)}
                />
                <div
                  className="table-cell"
                  style={{ paddingTop: '35em', position: 'relative' }}
                >
                  <h1>
                    TENNIS CLUB UCCLE
                    <br />
                    CHURCHILL
                  </h1>
                  <h3 className="box-headline letters rotate-2">
                    <span className="box-words-wrapper">
                      <b className="is-visible">Découvrez-nous.</b>
                      <b>Rejoignez-nous.</b>
                    </span>
                  </h3>
                </div>
              </section>
              <section
                id="team"
                className="team mbr-box mbr-section mbr-section--relative"
              >
                <svg
                  preserveAspectRatio="none"
                  viewBox="0 0 100 102"
                  height="100"
                  width="100%"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0 0 L50 100 L100 0 Z"
                    fill="#f8dd7b"
                    stroke="#f8dd7b"
                  />
                </svg>
                <div className="container">
                  <div className="col-md-8 col-md-offset-2 col-sm-12">
                    <div
                      className="row center mb-100"
                      style={{ marginTop: '5em' }}
                    >
                      <div className="section-title-parralax">
                        <div className="process-numbers">
                          <TennisIcon />
                        </div>
                        <h2>
                          Venez jouer au tennis à Uccle dans un cadre
                          sympathique et verdoyant !
                        </h2>
                        <p className="module-subtitle">
                          Qui n’a jamais rêvé de frapper la balle à Uccle, en
                          plein air, dans un cadre agréable, à un jet de balle
                          de l’avenue Winston Churchill ?
                        </p>
                        <p className="module-subtitle">
                          Situé dans le cadre verdoyant du parc Montjoie et ses
                          arbres centenaires, le Tennis Club Uccle Churchill
                          dispose de 4 terrains en terre battue.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bottom-separator hidden-xs" />
              </section>
            </Scroll.Element>
            <Scroll.Element name="ecole-de-tennis" id="ecole-de-tennis">
              <div
                id="features"
                className="features mbr-box mbr-section mbr-section--relative"
              >
                <div className="container">
                  <div className="row center">
                    <div className="col-md-8 col-md-offset-2 col-sm-12">
                      <div className="section-title-parralax">
                        <div className="process-numbers">
                          {' '}
                          <TennisIcon />
                        </div>
                        <h2>Ecole de tennis</h2>
                        <p className="module-subtitle">
                          L’école de tennis du Churchill fait désormais partie
                          d’un groupement de 3 écoles de tennis, toutes situées
                          à proximité du parc Montjoie à Uccle.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="row center">
                    <div className="col-md-8 col-md-offset-2 col-sm-12">
                      <div className="button-custom-container">
                        <Link
                          page={pagesInfos.ComponentEcoleDeTennis}
                          className="button-custom"
                        >
                          En savoir plus
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Scroll.Element>
            <Scroll.Element name="tennis-ete" id="tennis-ete">
              <section className="portfolio">
                <div
                  className="top-right-separator hidden-xs"
                  style={{
                    borderColor: 'transparent transparent #fcf3d3 transparent',
                    top: 'auto',
                    bottom: 0
                  }}
                />
                <div className="container">
                  <div className="col-md-8 col-md-offset-2 col-sm-12">
                    <div
                      className="row center"
                      style={{ margin: '5em 0 7em 0' }}
                    >
                      <div className="section-title mb-100">
                        <div className="process-numbers">
                          <TennisIcon />
                        </div>
                        <h2>Tennis l'été</h2>
                        <p className="module-subtitle">
                          Dès le mois d’avril, le Tennis Club Uccle Churchill,
                          petit club familial vous accueille comme chaque année,
                          dans la bonne humeur, pour les premiers échanges de
                          balles.
                        </p>
                        <div className="button-custom-container">
                          <Link
                            page={pagesInfos.ComponentTennisEte}
                            className="button-custom"
                          >
                            En savoir plus
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </Scroll.Element>
            <Scroll.Element name="tennis-couvert" id="tennis-couvert">
              <section
                id="services"
                className="services mbr-box mbr-section mbr-section--relative mbr-section--fixed-size mbr-section--bg-adapted"
                style={{ backgroundColor: '#fcf3d3', position: 'relative' }}
              >
                <div className="container">
                  <div className="row center" style={{ marginTop: '7em' }}>
                    <div className="col-md-8 col-md-offset-2 col-sm-12">
                      <div className="section-title mb-100">
                        <div className="process-numbers">
                          <TennisIcon />
                        </div>
                        <h2>Tennis couvert</h2>
                        <p className="module-subtitle">
                          Le Tennis Club Uccle Churchill dispose de 2 terrains couverts en hiver.
                        </p>
                        <div className="button-custom-container">
                          <Link
                            page={pagesInfos.ComponentTennisCouvert}
                            className="button-custom"
                          >
                            En savoir plus
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </Scroll.Element>
            <HomeNews />
            <HomeReferences />
            <Scroll.Element name="notre-equipe" id="notre-equipe">
              <section
                id="pricing"
                className="pricing mbr-box mbr-section mbr-section--relative mbr-section--full-height mbr-section--bg-adapted"
              >
                <div className="container">
                  <div className="row">
                    <div className="col-md-8 col-md-offset-2 col-sm-12">
                      <div className="row center">
                        <div className="section-title mb-100">
                          <div className="process-numbers">
                            {' '}
                            <TennisIcon />
                          </div>
                          <h2>Notre équipe</h2>
                          <p className="module-subtitle">
                            La gérance opérationnelle de notre club est assurée
                            par la famille Ulens qui vous propose une
                            restauration de qualité, des brunchs le dimanche,
                            des barbecues à la belle saison, des soirées «
                            endiablées » à supporter notre équipe nationale de
                            football…
                          </p>
                          <p className="module-subtitle">
                            Sébastien Ulens, responsable du site est
                            avantageusement secondé par sa maman, Maryse qui
                            vous accueille chaleureusement et est en permanence
                            aux petits soins avec les enfants.
                          </p>
                          <p className="module-subtitle">
                            Quant à Maurice, le père de Sébastien, son poignet
                            reste toujours ferme quand il s’agit de vous servir
                            une bière pression !
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="team-member col-md-6 col-sm-6 text-center">
                      <Img
                        src="https://storage.googleapis.com/innobelge-websites.appspot.com/sites/churchill/Sebastien.jpeg"
                        style={{ width: '100%', height: 'auto' }}
                      />
                    </div>
                    <div className="team-member col-md-6 col-sm-6 text-center">
                      <Img
                        src="https://storage.googleapis.com/innobelge-websites.appspot.com/sites/churchill/Maryse.jpeg"
                        style={{ width: '100%', height: 'auto' }}
                      />
                    </div>
                  </div>
                </div>
              </section>
            </Scroll.Element>

            <section
              id="testimonials"
              className="testimonials mt-100 mb-100 mbr-box mbr-section mbr-section--relative mbr-section--fixed-size mbr-section--bg-adapted mbr-parallax-background"
              style={{
                backgroundImage:
                  'url(https://storage.googleapis.com/innobelge-websites.appspot.com/sites/churchill/photo_p5.jpg)'
              }}
            >
              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                    <div
                      style={{
                        height: '25vh'
                      }}
                    />
                  </div>
                </div>
              </div>
            </section>

            <Scroll.Element name="contact" id="contact">
              <section
                id="contact"
                className="contact mbr-box mbr-section mbr-section--relative mbr-section--bg-adapted"
              >
                <div className="container">
                  <div className="col-md-6 col-sm-12">
                    <div className="row">
                      <h4>Espace Membre</h4>
                      <p className="libre-text mt-50">
                        Retrouvez notre espace de réservation sur le site
                        internet de balle jaune.
                      </p>

                      <div
                        className="button-custom-container"
                        style={{ textAlign: 'left' }}
                      >
                        <a
                          href="https://ballejaune.com/club/churchill"
                          className="button-custom"
                          target="_blank"
                          rel="noopener"
                        >
                          {' '}
                          Réservation{' '}
                          <i
                            className="fa fa-angle-double-right"
                            aria-hidden="true"
                          />
                        </a>
                      </div>

                      <p className="libre-text mt-50">
                        Club de tennis subsidié par la Commission communautaire française.
                      </p>

                    </div>
                  </div>

                  <div className=" col-md-offset-1 col-md-5 col-sm-12">
                    <div className="row center">
                      <div className="newsletter">
                        <h4>CONTACT</h4>
                        <p className="libre-text mb-50">
                          Des questions ? N’hésitez pas.
                        </p>
                        <Contact />
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </Scroll.Element>
          </PrimaryLayout>
        )
      }}
    />
  )
}

export default IndexPage
