import React, { Component } from 'react'
import { StyleSheet, css } from 'aphrodite'
import SvgIcon from '@material-ui/core/SvgIcon'

const styles = StyleSheet.create({
  root: {
    fill: '#fbe9ab',
  },
  fontSizeLarge: {
    fontSize: '120px'
  }
})

const Tennis = props => (
  <SvgIcon
    {...props}
    viewBox="0 0 100 100"
    fontSize="large"
    classes={{
      root: css(styles.root),
      fontSizeLarge: css(styles.fontSizeLarge)
    }}
  >
    <g transform="translate(0,-952.36218)">
      <path
        style={{
          textIndent: '0',
          textTransform: 'none',
          direction: 'ltr',
          blockProgression: 'tb',
          baselineShift: 'baseline',
          enableBackground: 'accumulate'
        }}
        d="m 50,958.36517 c -24.27684,0 -44,19.72249 -44,43.99853 0,20.6817 14.248361,38.2464 33.53125,42.936 a 2.0002,2.000132 0 1 0 0.9375,-3.8749 C 33.95359,1039.8403 28.076253,1036.6451 23.25,1032.2689 30.53523,1024.4225 35,1013.91 35,1002.3637 35,990.85956 30.550406,980.39183 23.3125,972.55219 30.389633,966.21364 39.738075,962.36504 50,962.36504 c 10.261925,0 19.610367,3.8486 26.6875,10.18715 C 69.449594,980.39183 65,990.85956 65,1002.3637 c 0,11.506 4.447409,21.974 11.6875,29.8115 -7.077133,6.3385 -16.425575,10.1871 -26.6875,10.1871 a 2.0029885,2.0029204 0 0 0 -0.21875,3.9999 2.0002,2.000132 0 0 0 0.21875,0 c 24.27684,0 44,-19.7225 44,-43.9985 0,-24.27604 -19.72316,-43.99853 -44,-43.99853 z M 20.46875,975.36459 C 26.999693,982.48396 31,991.93394 31,1002.3637 c 0,10.463 -4.025626,19.9374 -10.59375,27.0616 C 13.938013,1022.2687 10,1012.7494 10,1002.3637 c 0,-10.41377 3.961447,-19.88605 10.46875,-26.99911 z m 59.0625,0 C 86.038553,982.47765 90,991.94993 90,1002.3637 c 0,10.4137 -3.961447,19.886 -10.46875,26.9991 C 72.998794,1022.2461 69,1012.7947 69,1002.3637 c 0,-10.42976 4.000307,-19.87974 10.53125,-26.99911 z"
        fillOpacity="1"
        stroke="none"
        marker="none"
        visibility="visible"
        display="inline"
        overflow="visible"
      />
    </g>
  </SvgIcon>
)

export default Tennis
