import React, { Fragment, useState, useEffect } from "react";
import { StyleSheet, css } from "aphrodite";
import { MediaQueries } from "inno-components";
import cn from "classnames";
import axios from "axios";
import Img from "react-image";
import TennisIcon from "./icons/Tennis";

const styles = StyleSheet.create({
  container: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    height: "900px",
    [MediaQueries.small]: {
      height: "1100px"
    }
  },
  /*
  container: {
    columnCount: "2",
    columnGap: "1em",
    [MediaQueries.small]: {
      columnCount: "3",
      columnGap: "2em"
    }
  },*/
  item: {
    width: "50%",
    padding: "1em",
    //overflow: "hidden",
    [MediaQueries.small]: {
      width: "33.3%",
      padding: "2em"
    }
  },
  link: {
    width: "100%"
  },
  picture: {
    width: "100%"
  }
});

const HomeNews = () => {
  const [items, setItems] = useState([]);

  const fetchData = async () => {
    const result = await axios(
      "https://us-central1-innobelge-websites.cloudfunctions.net/facebook/357090751505838/posts?limit=6"
    );

    setItems(result.data.data);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const Items = items.map(item => (
    <div className={cn("photography", css(styles.item))} key={item.postId}>
      <a
        href={item.permalink}
        target="_blank"
        rel="noopener noreferrer"
        className={cn("portfolio_item", "work-grid", css(styles.link))}
      >
        <Img src={item.picture} alt="image" className={css(styles.picture)} />
        <div className="portfolio_item_hover">
          <div className="item_info" style={{ width: "100%" }}>
            <span>{item.message}</span>
          </div>
        </div>
      </a>
    </div>
  ));

  return (
    <section className="portfolio">
      <div className="top-right-separator hidden-xs" />
      <div className="container">
        <div className="col-md-8 col-md-offset-2 col-sm-12">
          <div className="row center">
            <div className="section-title mb-100">
              <div className="process-numbers">
                <TennisIcon />
              </div>
              <h2>News</h2>
              <p className="module-subtitle">
                Retrouvez les dernières actualités du clubs sur facebook.
              </p>
            </div>
          </div>
        </div>

        <div className="col-md-12">
          <div className={cn(css(styles.container))}>{Items}</div>
        </div>
      </div>
    </section>
  );
};

export default HomeNews;
