import React, { Component } from 'react'

import Scroll from 'react-scroll'

import IndexPage from '../components/_Home'
import SetLanguageFromPage from '../config/i18n/SetLanguageFromPage'

class Index extends Component {
  componentDidMount() {
    Scroll.scrollSpy.update()

    let hash = window.location.hash
    hash = hash.replace('#', '')

    if (hash && hash != '') {
      console.log('hash : ' + hash)
      Scroll.scroller.scrollTo(hash)
    }
  }
  render() {
    return <IndexPage />
  }
}

export default SetLanguageFromPage()(Index)
